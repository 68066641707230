import React from 'react'
import Header from "./components/header/header"
import Nav from "./components/nav/nav"
import About from "./components/about/about"
import Experience from "./components/experience/experience"
import Portfolio from "./components/portfolio/portfolio"
import Contact from "./components/contact/contact"
import Footer from "./components/footer/footer"
import Counter from "./components/counter/counter"


const App = () => {
  return (
    <>
        <Counter />
        <Header />
        <Nav /> 
        <About /> 
        <Experience />
        <Portfolio />
        <Contact />
        <Footer />
        
    </>
  )

 
}

export default App